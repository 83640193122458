<template>
  <div>
    <v-toolbar class="elevation-0 mx-3 rounded mb-2">
      <v-tabs>
        <v-tab @click="userPageShowHandler">Пользователь</v-tab>
        <v-tab @click="alertsPageShowHandler">Уведомления</v-tab>
      </v-tabs>
    </v-toolbar>

    <div v-if="activeSettingPage === 0">
      <v-card outlined="outlined" class="elevation-0 mx-3 mb-6">
        <v-card-title>
          О пользователе
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-form lazy-validation style="width: 100%">
              <v-col cols="12">
                <v-text-field
                  label="Имя"
                  v-model="userAccount.name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  disabled
                  :value="user.email"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  label="ID"
                  disabled
                  :value="user.id"
                  hide-details="auto"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Роль"
                  disabled
                  :value="user.type.id"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-row>
                <v-col></v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex justify-end">
                  <password-popup
                    button-text="Изменить пароль"
                    v-model="dialogState"
                  ></password-popup>
                  <v-btn
                    rounded
                    color="primary"
                    class="black--text mr-3"
                    @click="updateCurrentUser"
                    >Сохранить изменения</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-title>
          UI
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-form lazy-validation style="width: 100%">
              <v-col id="order-description" cols="12">
                <v-switch
                  :input-value="drawerMiniVariant"
                  @change="toggleMiniDrawer($event === true ? 'mini' : 'full')"
                  :label="`Мини вариант`"
                ></v-switch>
              </v-col>
              <v-col id="order-description" cols="12">
                <v-btn rounded @click="reloadAll()">Очистить кеш</v-btn>
              </v-col>
            </v-form>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div v-else class="alerts-settings">
      <v-card
        v-if="alertsSettings.length"
        outlined="outlined"
        class="elevation-0 mx-3"
      >
        <div class="d-flex align-center">
          <v-card-title class="mr-16">Настройки уведомлений</v-card-title>
          <v-btn
            rounded
            color="primary"
            class="black--text mr-3"
            @click="saveUserAlertsSettings"
            >Сохранить изменения</v-btn
          >
        </div>
        <div v-for="alertSetting in alertsSettings" :key="alertSetting.id">
          <v-card-text>
            <v-row>
              <v-col>
                <h4>{{ alertSetting.name }}</h4>
                <div class="d-flex">
                  <v-checkbox
                    label="На почту"
                    class="mr-4"
                    v-model="alertSetting.permissions[0].value"
                  >
                  </v-checkbox>
                  <v-checkbox
                    label="В интерфейсе"
                    v-model="alertSetting.permissions[1].value"
                  >
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </div>
      </v-card>
      <div v-else>Список настроек загружается...</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapMutations, mapState } from "vuex";
import passwordPopup from "../../../management/users/PasswordPopup";
import { AUTH_ABOUT_ME } from "../../../../store/const/auth";
import { UPDATE_USER } from "../../../../store/const/users";
import requestControl from "@/mixins/requestControl";

export default {
  props: {
    activeSettingPage: {
      type: Number,
      required: true
    },
    alertsSettings: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      dialogState: false,
      userAccount: {
        name: ""
      }
    };
  },
  created() {
    this.userAccount = JSON.parse(JSON.stringify(this.user));
  },
  components: {
    passwordPopup
  },
  mixins: [requestControl],
  computed: {
    ...mapState("Auth", ["drawerMiniVariant", "user"])
  },
  methods: {
    ...mapMutations("Auth", ["toggleMiniDrawer"]),
    ...mapActions("Users", {
      updateUser: UPDATE_USER
    }),
    ...mapActions("Auth", {
      getMe: AUTH_ABOUT_ME
    }),
    reloadAll() {
      localStorage.removeItem('orderHeaders');
      window.location.reload(true);
    },
    async updateCurrentUser() {
      await this.updateUser({
        id: this.user.id,
        data: { name: this.userAccount.name }
      });
      if (this.checkRequestOnError(UPDATE_USER)) {
        this.$notify({
          type: "succ",
          text: "Пользователь успешно обновлен"
        });
        await this.getMe();
      }
    },
    userPageShowHandler() {
      this.$emit('update:activeSettingPage',0);
    },
    alertsPageShowHandler() {
      this.$emit('update:activeSettingPage', 1);
    },
    saveUserAlertsSettings() {
      this.$emit('onSaveUserAlertsSettings');
    }
  },
};
</script>

<style scoped lang="scss">
.alerts-settings {
  height: 100%;
  max-height: calc(100vh - 235px);
  overflow-y: scroll;
}
</style>
