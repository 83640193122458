<template>
  <v-container
    class=" flex-nowrap flex-column"
    fluid="fluid"
    style="align-items: normal"
  >
    <view-toolbar title="Настройки">
      <template #buttons> </template>
    </view-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-skeleton-loader
          transition="scale-transition"
          width="100%"
          type="table"
        >
          <ui-settings
            :alerts-settings="alertsSettings"
            :active-setting-page.sync="activeSettingPage"
            @onSaveUserAlertsSettings="saveUserAlertsSettingsHandler"
          ></ui-settings>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import viewToolbar from "@/components/common/ViewToolbar";
import uiSettings from "./components/UISettings";
// import requestControl from "../../../mixins/requestControl";
import axios from 'axios';
import dbg from "@/plugins/dbg";

export default {
  components: {
    viewToolbar,
    uiSettings
  },
  // mixins: [requestControl],
  data() {
    return {
      bool: false,
      alertsSettings: [],
      activeSettingPage: 0
    };
  },
  created() {
    this.getUserAlertsSettings()
  },
  computed: {},
  methods: {
    async getUserAlertsSettings() {
      try {
        const response = await axios.get(`${this.$baseURL}alerts/settings`, {
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('userToken')}`
          }
        });
        this.alertsSettings = response.data.data;
      } catch (e) {
        dbg(e)
        this.$notify({ type: 'err', text: 'Ошибка при получении настроек' });
      }
    },
    async saveUserAlertsSettingsHandler() {
      const _data = this.alertsSettings;

      try {
        const response = await axios.patch(`${this.$baseURL}alerts/settings`, {
          alerts: _data,
        }, {
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('userToken')}`
          }
        });
      } catch (e) {
        dbg(e);
        this.$notify({ type: 'err', text: 'Ошибка при сохранении настроек' });
      }
    }
  }
};
</script>
