<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="702"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        color="primary"
        class="black--text mr-2"
        :block="$vuetify.breakpoint.smAndDown"
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span>Обновление пароля</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="isNew ? createNewUser() : updateCurrentUser()"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.old_password"
                :rules="rule.regularField"
                label="Старый пароль *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.new_password"
                :rules="rule.regularField"
                label="Новый пароль *"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <small>* Помечены обязательные поля</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog()">
          Отмена
        </v-btn>
        <v-btn
          rounded
          :loading="isLoading"
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="changePassword()"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";

import clientAgencyBlock from "./ClientAgencyBlock";
import { mapActions, mapState } from "vuex";
import { AUTH_PASSWORD_CHANGE } from "../../../store/const/auth";

let defaultForm = {
  new_password: "",
  old_password: ""
};

export default {
  components: {
    clientAgencyBlock
  },
  mixins: [validationRules, requestControl],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null,
      accessBlocks: [
        {
          agency: "",
          clients: []
        }
      ]
    };
  },
  computed: {
    ...mapState("Users", ["currentUser"]),
    isLoading() {
      return false;
    }
  },
  watch: {},
  methods: {
    ...mapActions("Auth", {
      editPassword: AUTH_PASSWORD_CHANGE
    }),
    async changePassword() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        let form = {
          ...this.form
        };
        await this.editPassword(form);
        if (this.checkRequestOnError(AUTH_PASSWORD_CHANGE)) {
          this.$notify({
            type: "succ",
            text: "Пароль успешно изменен"
          });
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      // this.form = Object.assign({}, defaultForm);
      this.$refs.form.resetValidation();
      // this.$refs.form.resetVal
    }
  }
};
</script>
